<template>
  <CRow>
    <CCol col="12" xl="12">

      <GoogleMapLoader
        :mapConfig="mapConfig"
        apiKey="AIzaSyA464qcmgzE7HTRTiN_m-09c9xeOb8LmsE"
        :setGoogleMap="setGoogleMap"
      >
      </GoogleMapLoader>    

    </CCol>
    <Notify ref="notifyDialog"/>
  </CRow>

</template>

<script>
import MarkerClusterer from '@googlemaps/markerclustererplus';
import GoogleMapLoader from '@/views/maps/GoogleMapLoader'
import GoogleMapMarker from '@/views/maps/GoogleMapMarker'
import GoogleMapLine from '@/views/maps/GoogleMapLine'

import utils from '@/services/utils'
import { MARKER_IMAGES as IMAGES } from "@/constants/constant"

import {
  mapSettings,
  LINE_PATH_CONFIG,
  DEFAULT_POSITION
} from '@/constants/mapSettings'

import { mapActions } from 'vuex'

export default {
  name: 'GoogleMap',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapLine
  },
  props: {
    device_guid: {
      type: String,
      default: undefined
    },
    sensor_type: {
      type: Number,
      default: 12
    },
    sensor_level: {
      type: Number,
      default: 0
    }
  },
  created: function() {
    // console.log('GoogleMap this.device_guid:', this.device_guid)
  },
  data () {
    return {
      google: null,
      map: null,

      devices: [],
      center_device_guid: undefined,
      sensor_types: utils.getSensorTypes(),
      center_device: undefined,
      alarm_zoom_level : 16,
      zoom_level : 16,
      cluster_min_level: 5,
      selected_marker: null,
      selected_device_guid: null,

      myLocationMarker: undefined,
      myLocationImage: undefined,

      markerClusterer: undefined,
      infoWindows: [],
      features: [],
      heatmapData: [],
      heatmap: null,

      mapSettings,
    }
  },

  computed: {
    mapConfig () {
      return {
        ...mapSettings,
        zoom: 11,
        minZoom: 2,
        maxZoom: 18,
        center: this.mapCenter
      }
    },

    mapCenter () {
      if (this.selected_device_guid == null)
        return DEFAULT_POSITION
      else
        return {
          lat: this.selected_device_guid.latitude,
          lng: this.selected_device_guid.longitude
        }
    }
  },

  methods: {
    setGoogleMap(google, map) {
      this.google = google;
      this.map = map
    },
    selectMarker(marker, googleMarker) {
      console.log('GoogleMap::selectMarker marker:', marker)
      console.log('GoogleMap::selectMarker googleMarker:', googleMarker)
    },
    getGeocode(latLng) {
      var self = this;
      // new this.google.maps.Geocoder.geocode({
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({
        'location': latLng
      }, function(result, status) {
        if (status === 'OK') {
          self.$emit('latLng', {
            latitude: latLng.lat(),
            longitude: latLng.lng(),
            address: result[1].formatted_address,
            road_address: result[0].formatted_address
          })
        }
      })
    },
    getGeoAddress(device) {
      var self = this;
      return new Promise((resolve, reject) => {
        // new self.google.maps.Geocoder.geocode({
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({
          'address': device.address
        }, function(result, status) {
          if (status === 'OK') {
            self.$emit('latLng', {
              latitude: result[0].geometry.location.lat(),
              longitude: result[0].geometry.location.lng(),
              address: result[0].formatted_address,
              road_address: result[0].formatted_address
            })
            resolve(result);
          } else {
            reject(result);
          }
        })
      })
    },
    buildContents(data, sensor_type) {
      var content = "";
      if (data && Array.isArray(data)) {
        var item = "";
        var type, id;
        for (var i = 0; i < data.length; i++) {
          type = data[i].type;
          id = data[i].id;
          if(type == 8){if(id > 3) break;}
          if (type == sensor_type) {
            item = utils.getSensorTypeName(type, id, false);
            content += '<tr>' +
              '<td>' + item + ':' + data[i].value + '' + '</td>' +
              '</tr>';
          }
        }
      }
      return content;
    },
    buildContents2(data, sensor_type) {
      return this.buildContents(utils.filteredItem(data, sensor_type), sensor_type);
    },
    buildPopupMessage(feature) {
      var self = this
      var url = '#/device/' + feature.guid;


      return new Promise((resolve, reject) => {
        // var content = '<p><strong>&nbsp;&nbsp;' + feature.name + '</strong></p>';
        var content = '<p style="font-family:arial;font-size: 9pt;"><b>' + feature.name + '</b></p>';
        content += '<table class="info-window-table">';
        content += '<table style="font-family:arial;font-size: 10pt">';
        if (!_.isEmpty(feature.sensor)) {
          content += self.buildContents2(feature.sensor.data, self.sensor_type);
          content += self.buildContents2(feature.sensor.data, self.sensor_level);
        }
        // self.buildContents2(feature.sensor.data, 8);
        content += '</table>';

        content += '&nbsp&nbsp<i class="fa fa-info-circle" aria-hidden="true"></i>';
        content += '&nbsp<a href="' + url + '"> 센서 상세보기 </a><br>';
        resolve(content);
      });
    },
    addMouseEvent(marker, feature, infowindow, type) {
      this.infoWindows.forEach(function(w) {
        w.close();
      });

      infowindow.open(this.map, marker);
      setTimeout(function() {
        infowindow.close();
      }, '3000');

      if (this.selected_marker != marker) {
        // this.getSensorData(feature.guid, feature.site_guid);
      }
      this.selected_marker = marker;
      this.selected_device_guid = feature.guid;
    },
    addEventHandler(marker, feature, cb) {
      var self = this;
      var content;
      this.buildPopupMessage(feature).then(function(r) {
        content = r;
        var infowindow = new self.google.maps.InfoWindow({
          position: feature.position,
          content: content,
          maxWidth: 1000
        });

        self.infoWindows.push(infowindow);
        self.features.push({
          device_guid: feature.guid,
          marker: marker
        })

        self.google.maps.event.addListener(marker, 'click', function() {
          // console.log("[map.ctrl] mouse click:", feature.guid);
          self.addMouseEvent(marker, feature, infowindow, 'click');
        });

        self.google.maps.event.addListener(marker, 'mouseover', function() {
          // console.log("[map.ctrl] mouse over:", feature.guid);
          self.addMouseEvent(marker, feature, infowindow, 'mouseover');
        });

        self.google.maps.event.addListener(marker, 'mouseout', function() {
          // console.log("[map.ctrl mouse out]:", feature.guid);
          // toggleImage(marker, feature.severity);
        });

        self.google.maps.event.addListener(marker, 'dragend', function() {
          self.getGeocode(marker.getPosition())
          // myLocationControlOff();
        });

        if (cb) {
          cb(marker);
        }
      });
    },
    getMarkerImage(sensor_type, level) {
      var sensor_type = sensor_type || 2;
      var level = level || 0;
      var imgsrc;

      switch (sensor_type) {
        case 12: // humidity
          sensor_type = 2;
          break;
        default:
          break;
      }
      Object.keys(IMAGES).some(function(key, i) {
        if (sensor_type === IMAGES[key].type && level === IMAGES[key].level) {
          imgsrc = IMAGES[key].name;
          return true;
        }
      });
      return imgsrc;
    },
    setMarkerImage(event) {
      var feature = _.find(this.features, {device_guid:event.device_guid}, undefined)
      if (feature === undefined) {
        return;        
      }
      var marker = feature.marker;

      var img = this.getMarkerImage(event.sensor_type, event.level);
      var size = new this.google.maps.Size(IMAGES.size.width, IMAGES.size.height);
      var markerImage = new this.google.maps.MarkerImage(img, size);
      marker.setIcon(markerImage);

      if (this.map.getZoom() !== this.alarm_zoom_level) {
        this.map.setZoom(this.alarm_zoom_level);
      }

      this.map.setCenter(marker.getPosition());
      this.selected_device_guid = event.device_guid;
      // getSensorData(event.device_guid, first_feature.site_guid);
    },
    setCenter(device) {
      if (typeof device === 'undefined') return;
      var level = _.isUndefined(this.device_guid) ? this.alarm_zoom_level : this.zoom_level;
      this.map.setZoom(level);
      this.map.setCenter(new this.google.maps.LatLng(device.latitude, device.longitude));
    },
    getClusterText(count) {
      return count + " 개";
    },
    registerEvent() {
      var self = this;
      function checkZoom(level) {
        if (level <= self.cluster_min_level) {
          var get = false;
          if (self.zoom_level === self.cluster_min_level + 1) {
            if (level === self.zoom_level - 1)
              get = true;
          }
          if (get) {
            self.selected_marker = undefined;
            self.selected_device_guid = undefined;
          }
        }
        self.zoom_level = level;
      }

      this.google.maps.event.addListener(this.map, 'zoom_changed', function() {
        var level = self.map.getZoom();
        // console.log("zoom_changed ", self.zoom_level, "->", level, "load_w:");
        checkZoom(level);
      });

      this.google.maps.event.addListenerOnce(this.map, 'idle', function() {
        var level = self.map.getZoom();
        // console.log("idle level:", level, ",cluster_min_level:", self.cluster_min_level);
        checkZoom(level);
      });

    },
    addCluster(devices) {
      var self = this;
      if (this.google === null) {
        console.log('GoogleMap::addCluster[not ready - wait a minute]')
        return;
      }
      var markers = devices.map(function(device, i) {
        var img = self.getMarkerImage(self.sensor_type, self.sensor_level);
        var size = new self.google.maps.Size(IMAGES.size.width, IMAGES.size.height);
        var markerImage = new self.google.maps.MarkerImage(img, size);

        var draggable = !_.isUndefined(self.device_guid)
        if (!draggable && device.guid === self.center_device_guid) {
          self.center_device = device;
        } else
        if (device.latitude == DEFAULT_POSITION.lat && device.longitude == DEFAULT_POSITION.lng) {
          self.center_device = device;
        }

        var m = new self.google.maps.Marker({
          position: new self.google.maps.LatLng(device.latitude, device.longitude),
          icon: markerImage,
          draggable: draggable
        });

        self.addEventHandler(m, device, function(marker) {
          // console.log('addEventHandler maker:', marker)
        })

        if (draggable) {
          self.myLocationMarker = m;
          self.setCenter(device)

          navigator.geolocation.getCurrentPosition(function(position) {
            var locationControlDiv = document.createElement('div');
            var locationControl = new myLocationControl(locationControlDiv, self.map);
            locationControlDiv.index = 1;
            self.map.controls[self.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationControlDiv);
          }, function(err) {
            console.log(err)
            // console.log('[device.ctrl] getCurrentPosition error: ' + err.toString())
          });
        }
        return m;
      });

      this.markerClusterer = new MarkerClusterer(
        this.map,
        markers, {
          // maxZoom: alarm_zoom_level,
          gridSize: 1,
          averageCenter: true,
          minimumClusterSize: this.cluster_min_level, //2
          texts: this.getClusterText,
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });

      this.setCenter(this.center_device);
      // map.setZoom(zoom_level);
      this.registerEvent()
    },
    addHeatMapData(info){
      var self = this;
      var value;
      info.forEach(device => {
        if (_.isUndefined(device.sensor)) return;
        device.sensor.data.forEach(data => {
          //weight test data
          if ((data.type === 6) && (data.id === 1)){
            if(data.type === 6)
              value = data.value <= 0 ? 0.1 : data.value/100;
            else
              value = Math.abs(data.value);
            //중첩데이터 삭제 조건 필요
            //console.log(self.heatmapData);

            self.heatmapData.push({
              location: new self.google.maps.LatLng(device.latitude, device.longitude),
              weight: value
            });
          }
        })
      })      
      if(!this.heatmap){
        this.heatmap = new this.google.maps.visualization.HeatmapLayer({
          data: this.heatmapData,
          radius: 80,
          //map: this.map
        });
      }
      this.heatmap.setMap(this.map);
    },
    clearFeatures() {
      if (!_.isUndefined(this.markerClusterer)) {
        var markers = []
        this.features.forEach(f => markers.push(f.marker))
        this.markerClusterer.removeMarkers(markers)
      }
      this.infoWindows.forEach(function(w) {
        w.close();
      })
      this.features.forEach(function(f) {
        f.marker.setMap(null);
      })

      this.infoWindows = [];
      this.features = [];
    },
    update(devices, center_device_guid) {
      // console.log('GoogleMap::update devices.data:', devices.data)
      if (_.isEmpty(devices.data)) {
        this.$refs.notifyDialog.show('지도 : 디바이스 정보가 없습니다', 500)
        this.clearFeatures();
        return;
      }
      this.devices = devices;
      var first_device = _.first(devices.data);
      if (_.isUndefined(center_device_guid)) {
        center_device_guid = first_device.center_device_guid
      }
      var center_device = _.find(devices.data, { guid: center_device_guid })
      if (_.isUndefined(center_device)) {
        center_device = first_device
      } else {
      }
      this.center_device_guid = center_device.guid

      this.clearFeatures();
      this.addCluster(this.devices.data)

      if(this.heatmap){
        this.heatmap.setMap(null);
        //this.heatmapData = [];
      }
  
      this.addHeatMapData(this.devices.data)
    },


    myLocationControlOff() {
      if (myLocationImage) {
        myLocationImage.style['background-position'] = '0 0';
      }
    },
    myLocationControl(controlDiv, map) {
      var firstChild = document.createElement('button');
      firstChild.style.backgroundColor = '#fff';
      firstChild.style.border = 'none';
      firstChild.style.outline = 'none';
      firstChild.style.width = '28px';
      firstChild.style.height = '28px';
      firstChild.style.borderRadius = '2px';
      firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
      firstChild.style.cursor = 'pointer';
      firstChild.style.marginRight = '10px';
      firstChild.style.padding = '0';
      firstChild.title = 'Your Location';
      controlDiv.appendChild(firstChild);

      var secondChild = document.createElement('div');
      secondChild.style.margin = '5px';
      secondChild.style.width = '18px';
      secondChild.style.height = '18px';
      secondChild.style.backgroundImage = 'url("img/mylocation-sprite-2x.png")';
      secondChild.style.backgroundSize = '180px 18px';
      secondChild.style.backgroundPosition = '0 0';
      secondChild.style.backgroundRepeat = 'no-repeat';
      myLocationImage = secondChild;
      firstChild.appendChild(secondChild);

      firstChild.addEventListener('click', function() {
        var imgX = '0',
          animationInterval = setInterval(function() {
            imgX = imgX === '-18' ? '0' : '-18';
            secondChild.style['background-position'] = imgX + 'px 0';
          }, 500);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            var position = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            this.getGeocode(position)
            this.myLocationMarker.setPosition(position)
            this.map.setZoom(alarm_zoom_level);
            this.map.setCenter(position);
            clearInterval(animationInterval);
            secondChild.style['background-position'] = '-144px 0';
          }, function(err) {});
        } else {
          clearInterval(animationInterval);
          secondChild.style['background-position'] = '0 0';
        }
      });
    }

  }
}
</script>

<style scoped lang='scss'>
  .google-map {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    border-top: solid 2px #FFF;
    border-bottom: solid 2px #FFF;
    &__map {
      height: 460px;
      width: 100%;
    }
    &__placeholder {
      height: 460px;
      width: 100%;
    }
  }
</style>